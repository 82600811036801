import { H1, H2, H3, H4, H5 } from 'components/01-atoms/Heading';
import { LinkConfig } from 'types/WebMicrofrontends';

export type CTAHeaderProps = {
  /** Optional classes to be added to the wrapper div. */
  classes?: string;
  /** Text to appear in header component. */
  headingText: string;
  /** Type of heading to appear in header component. */
  headingType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  /** Optional classes to be applied to the header component. */
  headingClasses?: string;
  /** Call to Action to appear on right. */
  linkConfig: LinkConfig;
};

const headingMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5
};

export const CTAHeader = ({
  classes,
  headingClasses,
  headingText,
  headingType,
  linkConfig
}: CTAHeaderProps) => {
  const HeadingComponent = headingMap[headingType];

  return (
    <div className={`${classes} grid grid-cols-2 items-center`}>
      <div className="text-left">
        <HeadingComponent
          headingText={headingText}
          classes={`${headingClasses} text-network-font-1 p-1`}
        />
      </div>
      <div className="text-right">
        {linkConfig && (
          <a
            className={`${
              linkConfig.classes || ''
            } rounded-md border border-solid border-black px-6 py-1 text-sm font-semibold uppercase tracking-wider shadow-sm`}
            href={linkConfig.href}
            target={linkConfig.target}
            onClick={linkConfig.onClick}
          >
            {linkConfig.text}
          </a>
        )}
      </div>
    </div>
  );
};
