import { useArticleTextAsJsx } from 'hooks/useArticleTextAsJsx';
import type { LinkConfig } from 'types/WebMicrofrontends';

export type MediaBlockTextProps = {
  /** Optional classes to be added to the wrapper div. */
  classes?: string;
  /** JSX to appear in header. */
  headerComponent?: JSX.Element;
  /** Rich text to appear in text block. */
  richText?: string;
  /** An array of text to appear as an unordered list in the block. */
  bulletedText?: string[];
  /** Optional button to appear below text block. */
  linkConfig?: LinkConfig & { className?: string };
};

export const MediaBlockText = ({
  classes = '',
  headerComponent,
  bulletedText = [],
  richText = '',
  linkConfig
}: MediaBlockTextProps) => {
  // If bulleted list has only one item, do not output <ul>, send to contentJsx
  const initialTextValue =
    bulletedText.length === 1 ? bulletedText[0] : richText;
  const [contentJsx] = useArticleTextAsJsx(initialTextValue);
  const isContent = bulletedText.length > 1 || contentJsx;

  return (
    <div className={`mfe text-left ${classes}`}>
      {headerComponent}
      {isContent && (
        <div className={`py-6 leading-7 ${contentJsx ? 'o-rich-text' : ''}`}>
          {contentJsx || (
            <div className="o-rich-text">
              <ul>
                {bulletedText.map((text, i) => (
                  <li key={`${text + i}`}>{text}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {linkConfig && (
        <a
          {...linkConfig}
          className={
            linkConfig.className ||
            'min-w-full cursor-pointer rounded-md border border-solid border-black px-6 py-1 text-sm font-semibold uppercase tracking-wider shadow-sm md:min-w-0'
          }
        >
          {linkConfig.text}
        </a>
      )}
    </div>
  );
};
