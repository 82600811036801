import { useState, useCallback } from 'react';
import parse from 'html-react-parser';
import { autop } from '@wordpress/autop';

export function useArticleTextAsJsx(initialContent: string) {
  const contentParse = (text: string) => parse(autop(text));
  const [articleContent, setArticleContent] = useState(() =>
    !initialContent ? null : contentParse(initialContent)
  );

  return [
    articleContent,
    useCallback(
      (newContent: string) => setArticleContent(contentParse(newContent)),
      []
    )
  ] as const;
}
