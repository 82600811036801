import {
  MediaBlockText,
  MediaBlockTextProps
} from 'components/02-molecules/MediaBlockText';
import { Image, ImageProps } from 'components/01-atoms/Image';

export interface MediaBlockProps {
  /** Optional classes to be added to the wrapper div. */
  classes?: string;
  /** Configuration of Media Block Text component. */
  textConfig: MediaBlockTextProps;
  /** Configuration of Image component. */
  imageConfig: ImageProps;
  /** Where the image should appear in the layout. */
  alignImage?: 'left' | 'right';
  /** Makes the image column take up a larger portion of the grid */
  imagePriority?: boolean;
}

export const MediaBlock = ({
  textConfig,
  imageConfig,
  alignImage = 'left',
  imagePriority = false,
  classes = ''
}: MediaBlockProps) => (
  <div
    className={`grid grid-cols-1 narrow-x:grid-cols-12 ${
      imagePriority ? 'items-start' : 'items-center'
    } ${classes}`}
  >
    {imageConfig?.src && (
      <div
        className={`col-span-1 ${
          imagePriority ? 'narrow-x:col-span-7' : 'narrow-x:col-span-6'
        } ${alignImage === 'right' ? 'narrow-x:order-last' : ''}`}
      >
        <Image {...imageConfig} />
      </div>
    )}
    <div
      className={`col-span-1 p-3.5 narrow-x:p-0 ${
        // eslint-disable-next-line no-nested-ternary
        imageConfig?.src
          ? imagePriority
            ? 'narrow-x:col-span-5'
            : 'narrow-x:col-span-6'
          : 'narrow-x:col-span-12'
      } narrow-x:px-6`}
    >
      <MediaBlockText {...textConfig} />
    </div>
  </div>
);
