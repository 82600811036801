import { H1 } from 'components/01-atoms/Heading';
import { PropsWithChildren } from 'react';

import { SectionBase } from './SectionBase';

export type SectionProps = {
  /** Optional classes to be added to the wrapper div. */
  classes?: string;
  /** Text to appear in header component. */
  headingText: string;
};

export const RubricHeaderSection = ({
  classes = '',
  headingText,
  children
}: PropsWithChildren<SectionProps>) => (
  <SectionBase
    classes={`text-left ${classes}`.trim()}
    HeadingComponent={
      <H1
        className="u-text--uppercase-lg my-8 ml-0 inline-block whitespace-nowrap border-b-2 border-network-black pb-2 pl-9 narrow-x:pl-12"
        headingText={headingText}
      />
    }
  >
    {children}
  </SectionBase>
);
