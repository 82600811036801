import { PropsWithChildren } from 'react';
import { LinkConfig } from 'types/WebMicrofrontends';
import { H1, H4 } from 'components/01-atoms/Heading';

export type SectionBaseProps = {
  HeadingComponent?: JSX.Element;
  SubHeadingComponent?: JSX.Element;
  classes?: string;
  headingText?: string;
  linkConfig?: LinkConfig & { className?: string };
  subHeadingText?: string;
};

export const SectionBase = ({
  HeadingComponent,
  SubHeadingComponent,
  children,
  classes = '',
  headingText,
  linkConfig,
  subHeadingText
}: PropsWithChildren<SectionBaseProps>) => (
  <section className={`${classes} flex flex-col items-center`.trim()}>
    <header className="w-full">
      {HeadingComponent ||
        (headingText && (
          <H1
            headingText={headingText}
            classes="text-2xl md:text-4xl text-network-font-1 font-semibold"
          />
        ))}
      {SubHeadingComponent ||
        (subHeadingText && (
          <H4 headingText={subHeadingText} className="md:text-lg" />
        ))}
    </header>
    <div className="w-full">{children}</div>
    <footer className="flex w-full items-stretch">
      {linkConfig?.text && (
        <a
          {...linkConfig}
          className={
            linkConfig.className ||
            'h-auto  w-full rounded bg-network-black text-network-white'
          }
        >
          {linkConfig.text}
        </a>
      )}
    </footer>
  </section>
);
